.l-footer {
  background-color: $footer-bg;
  color:$white;

  p {

    line-height: 1.6;
}
}
.footer-row > div {
  width: 25%;

  @include media-breakpoint-down(md) {
    width: 50%;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.footer-row a {
  color: $white;
  &:hover {
    text-decoration: underline;
  }
}



.footer-bar__left {
  column-gap: $spacer * 1.5;
  row-gap: $spacer;
}
.link-block {
  li {
    margin-bottom: $spacer * 0.75;
  }
  a {
    @include transition-fast;
  }
}

.block-contact .footer-contact-block {
  > a {
    font-weight: $weight-medium;
    display: inline-block;

  }
}

.footer__title {
  color:$white;
  &:visited,
  &:hover {
    color: inherit;
  }
  display: block;
  font-weight: $weight-medium;
  margin-bottom: $spacer;
  &--mobile {
    display: none;
    margin-bottom: $spacer;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after {
      content: "\e313";
      font-family: "Material Icons";
      font-feature-settings: "liga" 1;
      position: absolute;
      right: $grid-gutter-width/2;
      @include transition(0.3s transform ease-in-out);
      font-size: $font-size-base * 1.5;
      font-weight: 100;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav {
  align-items: center;
}
.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}

@include media-breakpoint-mobile {
  .footer-row {
    .footer-logo,
    .link-block {
      flex-basis: 100%;
    }
  }
  .footer__title {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
  .footer-bar__right {
    flex-grow: 1;
  }
}

.footer-logo {
  @include media-breakpoint-down(lg) {
      grid-area: 2 / 1 / 3 / 5;
  }

  @include media-breakpoint-down(sm) {
    grid-area: unset;
}
}
