.layout-home {
      .view-more {
        a {
          font-size: $font-size-base;
        }
        svg {
          width: $icon-xs-size;
          }
      }

      .cta-section {
        background-color: white;
        h2 {
          margin-bottom: $spacer * .75;
        }
        .description {
          font-size: $h2-font-size;
          font-weight: $weight-regular;
          line-height: $big-line-height;
        }
        &.alternate .gradient {
          background: linear-gradient(90deg, rgba(241,243,246,1) 0%, rgba(252,243,241,1) 100%);
        }
      }
}


.home-categories > div {
  margin: 0 -1rem;
  > a{
    background-color: $tertiary;
    > img {
      max-width: $spacer*15;
    }
  }

  .arr  {
    background-color: $white;
    right: $spacer;
    top: $spacer;
    min-height: $spacer*2.25;
    img {transform: rotate(-45deg);}

  }
}

.home-contact .grid-3 {
    grid-template-columns: 3fr 3fr 4fr;
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  
}
.hc-content {
  background:$primary;
  color: $white;
  a {
    color:$white;
    white-space: nowrap;
  }

}
@include media-breakpoint-down(xl) {
.address-block {
  grid-template-columns: 1fr;
}}

.about-content {
  background:#000;
  line-height: 1.6;
}

.cta-content {
  color:$white;
  @include media-breakpoint-down(sm) {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000045;
    left: 0;
    top: 0;
  }
  p, h2 {
    z-index: 2;
  }
  }
}


#discount-slider .homeslider_nav, #discount-slider .slick-arrow {
  display: none!important;
}

.discount-slider-content {
  background:white;
}

