.usp-wrapper {
    padding: ($spacer * 4) 0;
    row-gap: $spacer * 2.5;
    .usp-item {
        display: flex;
        align-items: center;
        gap: $spacer;
        .usp-icon {
            stroke-width: $usp-icon-stroke-width;
            width: $usp-icon-size;
            height: $usp-icon-size;
            color:$usp-icon-color;
        }
        .usp-title {
            font-size: $usp-title-font-size;
            font-weight: $usp-title-font-weight;
            margin-bottom: .25 * $spacer;
        }
        .usp-text {
            color: $secondary;
        }
    }
}