

button.slick-arrow {
  width: 25px;
  height: 25px;
  border: none;
  font-size: 0;
  margin: $spacer/2;
  background: url("/themes/4ws-extra-theme-v2/assets/img/arrow-right.svg") center center no-repeat;
  background-size:contain;
  &.slick-prev {
    background: url("/themes/4ws-extra-theme-v2/assets/img/arrow-left.svg") center center no-repeat;
  }
}

#homeslider {
  button.slick-arrow {
  position:relative;}
  .slick-prev {
    left:0;
}
.slick-next{
    right:0;
}
}


.carousel-arrow-navigation {
  right: $spacer*5;
  bottom: $spacer*5;
  @include media-breakpoint-down(sm) {
    right: $spacer;
    bottom: $spacer;
    }
}


.homepageslider-container {
  >.right {
    .slider-content {
      left:50%
    }
  }
  .slider-content {
    position:absolute
  }

  @include media-breakpoint-down(lg) {
    >.right {
      .slider-content {
        left:0;
      }

      img {
        object-position: left;
      }

      .slider-content::before {
        content: "";
        background: transparentize($black, .7);
        opacity: 0.8;
        position: absolute;
        left:0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
          }
    }

    >.left {

      img {
        object-position: right;
      }

      .slider-content::before {
        content: "";
        background: linear-gradient(125deg, transparentize($black, 0.2),  transparentize($black, 1));
        opacity: 0.8;
        position: absolute;
        left:0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
          }
    }

    .slider-content{
      position:relative;
      left: 0;
      width: 100%;
      
      

      >div {
        display: none;
      }
      
    }  

    img {
      position:absolute;

    }


  }
}

// @include media-breakpoint-down(xl) {
// #homeslider {
// .carousel-image{
//   min-height: $spacer*30;
//   max-height:$spacer*30
// }

// .slick-slide img{

//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
// }}



#homeslider, #discount-slider, .product-miniature {
  .styled {
    background: $secondary;
    border-radius: $border-radius-lg;
    padding: $spacer;
    font-size: $small-font-size;
    max-width: max-content;
  }
  

  ul.slick-dots {
    display: flex!important;}

}





.slider-content {
  color:$white;
  top: 0;
  min-height: 100%;
  width: 50%;



  * {
    z-index: 99999;
  }
  h1,h2 {
    font-size: clamp($spacer*1.75, 3vW, #{$display2-size})
  }
}