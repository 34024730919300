$search-btn-size: $spacer * 2.5;
$search-box-padding:    $spacer * .3125;

.search-widget {
  input[type="text"] {
  border-radius: $btn-radius;
  border: solid 2px $tertiary;
  &::placeholder {
    font-size: $small-font-size;
    color: $gray-400;
  }
}
button {
  right: 0;
  background: $tertiary;
  border-bottom-right-radius: $btn-radius;
  border-top-right-radius: $btn-radius;
}}
.search-widget__group {
  position: relative;
}
.search-widget__input-right {
  padding-right: $spacer * 3.125;
}


// .search-btn {
//   width: $spacer*3.125;
//   @include transition-fast;
//   border: none;
//   background:$gray-100;
//   &[disabled] {
//     pointer-events: none;
//   }
// }

// .search-box {
//   right: 0;
//   @include border-radius(3.125rem);
//   padding: $search-box-padding;
//   display: flex;
//   background: $gray-100;
// }
/* input */

#search_widget {
  max-width: 30%;
  @include media-breakpoint-down(md) {
    max-width: 100%;
    position: absolute;
    background: $black;
    padding: 1rem 1.25rem 1rem;
    left: 0;
    top: $spacer*5;
    display: none;
  }
}


.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  float: left;

  opacity: 0;
  @include transition-fast;

  font-size: $font-size-base;
  line-height: 2.5rem;
  color:$black;
}
.search-input::placeholder {
  color: $text-gray;
}
@include media-breakpoint-desktop {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box:hover > .search-input {
    width: 15rem;
    padding: 0 $spacer;
  }
}

.search-box:hover > .search-input,
.search-input:focus,
.search-input:not(:placeholder-shown){
  opacity: 1;
}

.search-btn:not([disabled]):hover {
  background-color: $white;
}

@include media-breakpoint-mobile {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box:hover > .search-input 
  {
    width: auto;
    padding: 0 $spacer;
  }

  .search-input button {
    @include transition-fast;
  }

  .search-input:focus ~ button,
  .search-input:not(:placeholder-shown) ~ button,
  .search-box:hover > .search-input ~ button {
    max-width: 0;
    opacity: 0;
    padding: 0;
  }
}
