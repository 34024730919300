.container, .container-lg, .container-md, .container-sm, .container-xl{
    max-width: unset;
}
.hidden {
  display: none;
}
.fw-black {
    font-weight: $weight-black;
}
.fw-bold {
    font-weight: $weight-bold;
}

.fw-med {
    font-weight: $weight-medium;
}

.fw-reg {
    font-weight: $weight-regular;
}

.f-color {
    color:$font-color
}

.f-color2 {
    color:$primary
}

.icon {
  fill:$primary
}

.icon-green {
    fill:$green
  }

.fs-2 {
    font-size: $display2-size    
}

.fs-3 {
    font-size: clamp($spacer*1.75, 3vW, #{$display3-size})
}

.fs-4 {
    font-size: $display4-size    
}

.fs-sm {
    font-size: $small-font-size;
    line-height: 1.6;    
}

.fs-xs {
    font-size: $font-size-base*.75; 
}

.bg-sec {
    background-color: $tertiary;
}

.big-rad {
    border-radius: $btn-radius;
}

.sm-rad {
    border-radius: $border-radius-lg;
}

.g-1 {
    gap:$spacer
}

.g-2 {
    gap:$spacer*2
}

img:not(.img-cover):not(.img-contain) {
    max-width: 100%;
    height:auto
}

.img-cover {
    width: 100%;
    height:100%;
    object-fit: cover;
}
.img-contain {
  width: 100%;
  height:100%;
  object-fit: contain;
}

.grid-2, .grid-3, .grid-4, .grid-5 {
    display: grid;
}

.grid-2 {
    grid-template-columns: repeat(2,1fr);
}

.grid-3 {
    grid-template-columns: repeat(3,1fr);
}

.grid-4 {
    grid-template-columns: repeat(4,1fr);
}

.grid-5 {
    grid-template-columns: repeat(5,1fr);
}


@include media-breakpoint-down(lg) {
    
    .grid-3 {
        grid-template-columns: repeat(2,1fr);
    }
    
    .grid-4 {
        grid-template-columns: repeat(3,1fr);
    }
    
    .grid-5 {
        grid-template-columns: repeat(4,1fr);
    }
}

@include media-breakpoint-down(md) {
    
    .grid-4 {
        grid-template-columns: repeat(2,1fr);
    }
    
    .grid-5 {
        grid-template-columns: repeat(3,1fr);
    }

    .grid-2 {
    grid-template-columns: repeat(1,1fr);
}
}

@include media-breakpoint-down(sm) {
    
    .grid-5 {
        grid-template-columns: repeat(2,1fr);
    }
}

@include media-breakpoint-down(xs) {
    
    .grid-2, .grid-3, .grid-4, .grid-5 {
        grid-template-columns: repeat(1,1fr);
    }
}
#carousel {
    height: 100%;
    max-height: auto;
    @include transition-slow;
    &::before {
      content:"";
      background: url(/themes/4ws-extra-theme-v2/assets/img/loader.svg) no-repeat center;
      background-size: $spacer*4;
      width: 100%;
      height: 100%;
      position: absolute
    }
    
    &:not(.slick-initialized){
      max-height: 512px;
      @include transition-slow;
      .carousel-image {
        visibility: hidden;
        @include transition-slow;
      }
    }
    &.slick-initialized{
      max-height: auto;
      .carousel-image {
        visibility: auto;
      }
    }
}

.alert-warning {
    background-color: $tertiary;
    border-color: $tertiary;
    color: $primary;
  }
  .alert-info {
    background-color: $secondary;
    border-radius: $btn-radius;
    color: $primary;
  }

#category-description-full {
    display: none;
  }

  a {
    color:inherit
  }

  p a {
    color:$primary
  }
.card {
    box-shadow: 0 15px 15px rgba(10,10,10,.03);
    background-color: $white;
    @include transition-fast;
    a {
      @include transition-fast;
    }
    &.hover:hover {
      box-shadow: $box-shadow-lift-up;
      transform: translateY(-0.25rem);
    }

    @include media-breakpoint-down(md) {
        box-shadow: 0 15px 15px rgba(10,10,10,.06);
        margin-bottom: $spacer;
    }
  }
  @include media-breakpoint-down(md) {
  .bg-white.big-rad {
    box-shadow: 0 15px 15px rgba(10,10,10,.02);
    margin-bottom: 2rem!important;
}}
// Button
select, input:not([type=checkbox]):not([type=radio]):not(.js-cart-line-product-quantity), .custom-file, .custom-file-input, .custom-file-label, .custom-file-label:after, .custom-select {
    min-height: $spacer*3.125;
    height: unset;
    
}

.form-control, .form-control[type=email], .form-control[type=text],.custom-file-input, .custom-file-label, .custom-file, .custom-select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius:$border-radius-lg;
    border-color:$gray-200
}

.block_newsletter input[type=email]{
    border-radius: $btn-radius;
}
.custom-file-label:after {
    display: flex;
    align-items: center;
}
a:hover {
    color:$primary
}
.btn {
    padding: $spacer;
    position: relative;
    overflow: hidden;
    z-index: 10;
    border: none;
    border-radius: $btn-radius;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $spacer * 0.25;
    @include transition-fast;
    box-shadow: none;
    font-weight: $weight-medium;

}

.add-to-cart {
    padding: $spacer*1.25;
}

.btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus, .btn.focus, .btn:focus {
  box-shadow: none;
}

.btn-primary {
    &.bt-white:hover {
        color:$primary
    }
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        opacity: $btn-icon-opacity;
    }
}

.icon-white {
    fill:$white
}

.btn-inner {
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
    background-color: $black;
    color: $white;
}

.btn-white {
    background: $white;
    color:$font-color;
    &:hover {
        color:$white
    }
}

.btn-outline-primary {
    border:solid 1px $primary;
    color:$primary;
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        opacity: $btn-icon-opacity;
    }

    &.btn-white:hover {
        background-color: white;
        color: $primary;

    }
}

.card {
    border: none;
}

.card-body {
    position: relative;
    @include media-breakpoint-mobile(up) {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
    @include media-breakpoint-mobile() {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
}

// Form

.form-control {
    height: unset;
    box-shadow: none;
        border-color: lighten($primary, 25%);
        &:focus,
        &:active {
            border-color: $primary;
        }
    

}
.custom-select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, $primary 50%), linear-gradient(135deg, $primary 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 20px) calc(1.2rem + 2px),calc(100% - 15px) calc(1.2rem + 2px),100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    box-shadow: none;
}



input[type="radio"]:checked {
    background-color: $primary;
   }
   input[type="checkbox"]::before {
    box-shadow: inset 1em 1em $primary;
  }
  input[type="checkbox"]:focus {
    outline-color:$primary;
  }


input:focus-visible {
  outline: none!important;
  border: none;
}

.custom-control-input:checked~.custom-control-label:before {
    background-color: $primary;
}

// blog

.post_image {
    img {
        width: 100%;
    }
}

h2.sdsarticleTitle {
    font-size: $font-size-base;
}

.articleImageContent img {
    max-height: $spacer*15;
}

@include media-breakpoint-down(lg) {
    .lastest_posts {
        li.post:last-child {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .lastest_posts {
        li.post:last-child {
            display: inherit;
        }
    }
}

  
  


// guest tracking
#guestOrderTrackingForm .alert p:last-child {
  margin-bottom: 0;
}



button.wishlist-button-add {
    box-shadow: none;
    background-color: none;
}

#password, #authentication.page-customer-account {
    #content-wrapper {
        max-width: $spacer*33;
    }

    .form-group.col-lg-6 {
        max-width: 100%;
        padding: 0;
    }
}