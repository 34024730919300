.block-categories {
  box-shadow:$card-box-shadow;;
}



//block categories
.block-categories {
  a{
    color:$body-color;
  }

  li.chosen {

    >div>a{
      font-weight: $weight-bold;}
  }

  .first-line.bg-primary > a{
    color:$white;
}

.first-line.bg-primary > i{
 display:none}
  
}

.first-line.bg-primary >div>.close {
  display: none;
}

.first-line:not(.bg-primary) >div>.open {
  display: none;
}
.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}

.collapse-icons {
  width: $spacer;
  height: $spacer;
  img {
    right: 0;
    top: $spacer/2;
  }
}


.featured-categories {
  margin-bottom: $spacer * 4;
  .row {
    row-gap: $spacer * 2.5;
  }
  
  .category-thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: $spacer*8;
      object-fit: scale-down;
    }
  }
  .category-item .card {
    min-height: 100%;
  }
  
  
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    >div, a {
      flex-basis: 50%;
    }
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $font-size-base;
    }
  }
}