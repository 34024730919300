.toast-title {
  font-weight: $weight-medium;
}
.toast-message {
  word-wrap: break-word;
  font-size: $font-size-base;
}
.toast-message a,
.toast-message label {
  color: $white;
}
.toast-message a:hover {
  color: $gray-400;
  text-decoration: none;
}
.toast-close-button {
  cursor: pointer;
  text-decoration: none;
  color: $white;
  width: $icon-normal-size;
  height: $icon-normal-size;
  opacity: 0.5;
  @include transition-fast;
}
.toast-close-button:hover,
.toast-close-button:focus {
  opacity: 1;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: $spacer;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 1rem;
  left: 1rem;
}
.toast-top-right {
  top: 1rem;
  right: 1rem;
}
.toast-bottom-right {
  right: 1rem;
  bottom: 1rem;
}
.toast-bottom-left {
  bottom: 1rem;
  left: 1rem;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}
#toast-container > div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  pointer-events: all;
  gap: $spacer / 2;
  margin-bottom: $spacer / 2;
  padding: $spacer;
  width: 30rem;
  @include border-radius($border-radius-lg);
  color: $white;
  opacity: 1;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 30rem;
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: $off-black;
}
.toast-success {
  background-color: $green;
}
.toast-error {
  background-color: $red;
}
.toast-info {
  background-color: $gray-600;
}
.toast-warning {
  background-color: #f89406;
}
.toast-message {
  flex: 1;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.25rem;
  background-color: $black;
  opacity: 0.125;
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 1.5rem;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 1.5rem;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 1.5rem;
    width: 25em;
  }
}
