@import 'simplebar';

.blockcart:hover,
.blockcart.hovered {
    .cart-preview {
      transform: translate(0, $spacer) rotateX(0);
      transform-style: preserve-3d;
      opacity: 1;
      pointer-events: all;
      @include media-breakpoint-down(sm) { 
        display: none;
      }
    }
  }

.cart-preview {
    max-width: 100vw;
    width: $cart-preview-width;
    pointer-events: none;
    position: absolute;
    right: 0;
    top:$spacer*7;
    z-index: 99999;
    opacity: 0;
    transform-origin: bottom center;
    transform: translate(0, -$spacer/2) rotateX(-15deg);
    @include transition(.3s ease);
  
    .cart-content {
      position: relative;
      min-height: $cart-preview-item-min-height;
      background: $white;
      box-shadow: $box-shadow-md;
      padding: $cart-preview-padding-y $cart-preview-padding-x;
    }
  
    .content-list {
      padding: 0 $spacer;
      margin: $spacer 0;
      max-height: Min((#{$cart-preview-item-min-height * $cart-preview-items-visible}), 33vh);
      position: relative;
      overflow-x: hidden;
      .simplebar-scrollbar::before {
        background-color: $gray-500;
      }      
    }
  
    .cart-empty {
      opacity: 0;
      animation: fadeInUp .3s ease 0s forwards 1;
      min-height: $cart-preview-item-min-height;
    }

    .simplebar-content {
      overflow-x: hidden;
    }


    .preview-item {
      padding-bottom: $spacer;
      overflow: hidden;
      opacity: 1;
      transform: scale(1);
      @include transition(.3s ease);
      .input-group {
        box-shadow: none;
        border:none;
        width: auto;
        height: $spacer;
        .form-control {
          max-width: 2.5rem;
          height: $spacer;
          padding: 0 $spacer/2;
          font-size: $tiny-font-size;
        }      
        .input-group-btn {
          position: relative;
          overflow: hidden;
          height: $spacer;
          &:before,
          &:after {
            display: none;
          }


          &:first-of-type:after {
            transform-origin: left;
          }
          &:last-of-type:after {
            transform-origin: right;
          }
          &:hover:after, &:focus:after, &:active:after {
            transform: scaleX(1);
          }
            button {
            @include transition(.15s ease);
            padding: 0 $spacer/2;
            background-color: transparent;
            &:hover, &:active, &:focus {
              color: $primary;
            }
          }
        }
      }
      .product-image {
        width: 20%;
        min-width: 20%;
      }


      .product-name {
        color: $text-black;
        font-size: $small-font-size;
        line-height: $line-height-base;
        margin-bottom: 0;
      }
      
      .product-price {
        font-size: $small-font-size;
        font-weight: $cart-preview-product-price-font-weight;
      }
      
      .product-quantity {
        font-size: $tiny-font-size;
        color: $gray-500;
      }
      
      .product-attributes {
        font-size: $tiny-font-size;
        color: $gray-500;
        strong {
          color: $gray-700;
        }
      }
    }
      
    .cart-subtotals,
    .cart-totals {
      font-size: $small-font-size;
      color: $text-gray;
    }

    .remove-from-cart {
      svg {
        color: $primary;
        @include transition(0ms);
        transform: scale(1);
      }
      &:hover {
        svg {
          color: $white;
          fill: $red;
          transform: scale(1.1);
        }
      }
    }

    .cart-footer {
      padding: $spacer;
      min-height: $cart-preview-item-min-height;
      overflow: hidden;
    }
    
    .cart-spinner {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      &.removed {
        opacity: 0;
        transform: scale(.75);
      }
    }
    
    .preview-item,
    .cart-footer {
      &.removed {
        padding-bottom: 0;
        min-height: 0;
        opacity: 0;
        transform: scale(.75);
        pointer-events: none;
        max-height: 0;
      }
    }
    

  }
 

@keyframes fadeInUp {
  from {
      transform: translate3d(0,2rem,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@include media-breakpoint-mobile {
  .cart-preview {
    transform: translate(0, $spacer) rotateX(0);
    padding-top: $spacer;
    .cart-content {
      padding: 0;
    }
    .cart-footer {
      padding-bottom: $spacer;
    }
  }

  // .blockcart:hover,
  // .blockcart.hovered {
  //   .cart-preview {
  //     transform: translate(0) rotateX(0);
  //   }
  // }
}