@import "variables/layout";
@import "variables/product-miniature";
@import "variables/searchbar";

//BS touchspin
$bs-touchspin-boxshadow:        2px 4px 12px 0 rgba(0,0,0,.08);

//cart
$promo-code-bg:                 darken($body-bg,10%);

//offcanvas
$zindex-offcanvas :             $zindex-modal;
$offcanvas-width :              300px;

//product slider
$product-thumb-margin:          5px;
$product-thumb-width:           100px;
$product-thumb-height:          100px;
$product-to-show:               3; //if you edit this, you have to add centerPadding ppy in data-slick with same value
$product-thumb-wrap-width:      $product-to-show * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-width-2:    2 * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-height:     $product-thumb-height + $product-thumb-margin * 2;

//prices
$product-price-current-color:               null;
$product-price-current-font-size:           $font-size-base * 1.5;
$product-price-current-font-weight:         $weight-bold;
$product-price-current-font-family:         null;

$product-price-regular-color:               $gray-300;
$product-price-regular-font-size:           $font-size-base;
$product-price-regular-font-weight:         $weight-medium;
$product-price-regular-font-family:         null;


$product-price-discount-color:              $black;
$product-price-discount-font-size:          null;
$product-price-discount-font-weight:        $weight-bold;
$product-price-discount-font-family:        null;




//product color box
$colorbox-border-color:         #232323;

//product add to cart spinner
$spinner-width-addtocart:$btn-font-size-lg;
$spinner-height-addtocart:$btn-font-size-lg;
$spinner-borderwidth-addtocart:2px;


//social share
$social-share-size:             2.5 * $font-size-base;
$social-share-bg-color:         $gray-200;

//main menu
$menu-topitem-font-size:            $font-size-base;
$menu-topitem-font-weight:            600;
$menu-topitem-color:            $gray-700;
$menu-topitem-text-transform:   uppercase;
$menu-topitem-padding:          $spacer/2;
$menu-topitem-margin-left:          $spacer*2;

$menu-sub-top: 60px;

  //main menu mobile
$menu-item-padding:             $spacer/2;
$menu-item-border-color:        $border-color;
$menu-sub-mobile-bg-color:      $wrapper-bg;

//heading
$h1-text-transform: none;

//util
//$card-box-shadow : $box-shadow !default;
$card-box-shadow : null !default;

//product section

$section-title-font-family: null;
$section-title-color: null;
$section-title-size: null;
$section-title-font-weight: null;
$section-title-text-align: center;
$section-title-text-transform: uppercase;
$section-title-margin-bottom: $spacer;

//blockcart
$blockcart-color: null;
$blockcart-background-color: null;
$blockcart-inactive-color: null;
$blockcart-inactive-background-color: null;
$blockcart-padding:null;


//block left column
$block-border-color: null;



// Cart preview
$cart-preview-product-name-font-weight: 800;
$cart-preview-product-price-font-weight: 800;
$cart-preview-width:        28rem;
$cart-preview-padding-y:    $spacer;
$cart-preview-padding-x:    $spacer;
$cart-preview-item-min-height: 6rem;
$cart-preview-items-visible: 3;

// Dropdown defaults
$dropdown-min-width:            28rem;
$dropdown-default-width:        100vw;

// Card
$card-border-radius:  $border-radius-lg;
$card-bg:             $white;

.card {
  border: none;
}

.card-body {
  position: relative;
  @include media-breakpoint-mobile(up) {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
  @include media-breakpoint-mobile() {
    padding: ($spacer * 1.5) ($spacer * 1.5);
  }
}

// Form

.form-control { 
  &[type=email],
  &[type=text] {
    padding: ($spacer * .75) ($spacer * 1);
    border-radius: $border-radius-lg;
    border-color: lighten($primary, 25%);
    &:focus,
    &:active {
      border-color: $primary;
    }
  }
}

.custom-checkbox {
 
  .custom-control-label:before {
    @include border-radius(50%);
  }
  .color__label {
    margin-left: $spacer*.5;
    min-height: $spacer*2;
  }
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-position: right ($spacer * 1) center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMxMGM1NTAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hlY2siPjxwb2x5bGluZSBwb2ludHM9IjIwIDYgOSAxNyA0IDEyIj48L3BvbHlsaW5lPjwvc3ZnPg==")
}

.custom-control-label {
  color: $gray-500;
  gap: $spacer * 0.5;
  margin:$spacer/2 0 ;
}

.custom-control-label:after, .custom-control-label:before {
  top:0
}

.dropdown-menu {
  width: max-content;
  max-width: 100vh;
  padding: $spacer * 2;
  border: none;
  box-shadow:  $box-shadow-lg;
  @include border-radius($spacer);
}

.breadcrumb-item+.breadcrumb-item:before {
  content: "";
  background:url(/themes/4ws-extra-theme-v2/assets/img/arrow-right.svg) 50% no-repeat;
  background-size: contain;
  width: $spacer*.75;
  height: $spacer*1.5;
  opacity:.4
}

.breadcrumb-item+.breadcrumb-item{
  color: $gray-600;
  padding-left: 0;
}